.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}


.App-header {
  background-color: #585568;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.homeText p
{
  width:400px;
  margin:30px auto;
  display: block;
  line-height: 1.5em;
}

.homeText p b
{
  font-weight: 600;
  color: #FF9300;
}

.homeText a
{
  font-size: 1.5em;
  font-weight: 300;
  color: #FF9300;
}

